import React, { useState, useEffect, useRef } from "react"

import { graphql } from "gatsby"

import Braingineers from "../images/work/braingineers.png"
import Gloa from "../images/work/gloa.png"
import Burst from "../images/work/burst.png"
import Counters from "../images/work/counters.png"
import CentralApp from "../images/work/centralapp.png"
import Nxchange from "../images/work/nxchange.png"
import Coffee from "../images/coffee.svg"
import Adyen from "../images/work/adyen.png"
import Reddit from "../images/reddit.svg"
import Hireproof from "../images/work/hireproof.png"
import Facebook from "../images/facebook.svg"
import Spotify from "../images/spotify.svg"
import Instagram from "../images/instagram.svg"

import Img from "gatsby-image"

import SEO from "../components/seo"
import Layout from "../components/layout"

import "./styles.scss"

const IndexPage = ({ data }) => {
  const [ufoLeft, setUfoLeft] = useState(0)
  const [wonked, setWonked] = useState(false)
  const [scrollIndicator, setScrollIndicator] = useState(false)
  const ufo = useRef(null)
  useEffect(() => {
    const fromTop = ufo.current.getBoundingClientRect().top
    const handleScroll = event => {
      const percentage =
        100 - (ufo.current.getBoundingClientRect().top / fromTop) * 100
      const withTopBounds = percentage > 100 ? 100 : percentage
      const withBounds = withTopBounds < 0 ? 0 : withTopBounds
      if (withBounds > 20) {
        setScrollIndicator(true)
      } else {
        setScrollIndicator(false)
      }
      setUfoLeft(withBounds)
    }
    document.addEventListener("scroll", handleScroll)
    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <Layout>
      <SEO
        title="Nick Barth | Home"
        description="Home of frontend developer Nick Barth, Amsterdam, NL"
      />
      <div className="wonk-container">
        <div className="wonk-icon" onClick={() => setWonked(!wonked)}>
          {<Coffee />}
        </div>
      </div>
      <div className="socials">
        <div className="social">
          <a
            href="https://open.spotify.com/user/1215590034"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Spotify />
          </a>
        </div>
        <div className="social">
          <a
            href="https://www.facebook.com/mynameisnickbarth"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Facebook />
          </a>
        </div>
        <div className="social">
          <a
            href="https://www.instagram.com/barth.nicholas/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Instagram />
          </a>
        </div>
        <div className="social">
          <a
            href="https://www.reddit.com/u/nickinkorea"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Reddit />
          </a>
        </div>
      </div>
      <section className={`splash ${wonked && "splash--wonked"}`}>
        <div className="splash-container">
          <div className="image-container">
            <Img
              fixed={data.file.childImageSharp.fixed}
              className="splash-photo"
              alt="Smiling Nick Barth"
            />
          </div>
          <div className="splash-content">
            <h1 className="splash-hello">
              Hello, I'm{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={data.allFile.edges[0].node.publicURL}
              >
                Nick Barth
              </a>
              .
            </h1>
            <div className="splash-about-me">
              founder at{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.hireproof.io/"
              >
                Hireproof
              </a>
              , based out of{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://en.wikipedia.org/wiki/Utrecht"
              >
                Utrecht
              </a>
              , constantly striving to solve problems through{" "}
              <a
                href="https://github.com/nick-barth"
                rel="noopener noreferrer"
                target="_blank"
              >
                code
              </a>
              . In addition to honing my craft, I{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.hackyourfuture.net/"
              >
                teach
              </a>
              ,{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.goodreads.com/user/show/60480445-nick-barth"
              >
                read
              </a>
              ,{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://medium.com/@nickbarth_277"
              >
                write
              </a>
              ,{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.twitch.tv/star_ansible"
              >
                stream
              </a>
              ,{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://open.spotify.com/episode/3CsfQ6SuNtWzBHDXLlBxCf?si=4c3817ec09c34b78"
              >
                podcast,
              </a>{" "}
              and{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.nick-barth.com/pooplog/"
              >
                poop
              </a>
              .
              <div className="crab-holder">
                <span
                  role="img"
                  aria-label="crab man"
                  ref={ufo}
                  style={{ left: `${ufoLeft}%` }}
                  className="ant"
                >
                  🦀
                </span>
              </div>
            </div>
          </div>
        </div>
        <section
          className={`section10 ${scrollIndicator && "section10--scrolled"}`}
        >
          <div href="#thanks">
            <span></span>
          </div>
        </section>
      </section>
      <section>
        <div className="work">
          <h2 className="work-title">Current Work:</h2>
          <div className="work-container">
            <div className="work-item">
              <img
                alt="hireproof"
                className="work-src work-hireproof"
                src={Hireproof}
              ></img>
              <div className="work-content">
                <h3 className="work-conent-title">
                  <a
                    href="https://www.hireproof.io/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Hireproof
                  </a>
                </h3>
                <p className="work-conent-title work-content-p--first">
                  Product
                </p>
                <p className="work-content-p">Making hiring human</p>
              </div>
            </div>
          </div>

          <h2 className="work-title">Past Work:</h2>
          <div className="work-container">
            <div className="work-item">
              <img
                alt="adyen"
                className="work-src work-adyen"
                src={Adyen}
              ></img>
              <div className="work-content">
                <h3 className="work-conent-title">
                  <a
                    href="https://www.adyen.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Adyen
                  </a>
                </h3>
                <p className="work-conent-title work-content-p--first">
                  Vue, Java, point of sale terminal work
                </p>
                <p className="work-content-p">
                  Europe's largest payment provider
                </p>
              </div>
            </div>
          </div>
          <div className="work-container">
            <div className="work-item">
              <img
                alt="braingineers"
                className="work-src work-braingineers"
                src={Braingineers}
              ></img>
              <div className="work-content">
                <h3 className="work-conent-title">
                  <a
                    href="https://braingineers.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Braingineers
                  </a>
                </h3>
                <p className="work-conent-title work-content-p--first">
                  Vue dashboard & React internal tooling
                </p>
                <p className="work-content-p">
                  Unlocking the emotional experience with neurousibility on
                  UI/UX
                </p>
                <p className="work-content-p">
                  Architecture, implementation, design by{" "}
                  <a
                    href="https://www.sandercromba.ch/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sander Crombach
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="work-container">
            <div className="work-item">
              <img
                alt="braingineers"
                className="work-src work-gloa"
                src={Gloa}
              ></img>
              <div className="work-content">
                <h3 className="work-conent-title">
                  <span>Gloa</span>
                </h3>
                <p className="work-conent-title work-content-p--first">
                  Gatsby marketing site and Next.js app
                </p>
                <p className="work-content-p">Read or die</p>
                <p className="work-content-p">
                  Architecture, implementation, design
                </p>
              </div>
            </div>
          </div>
          <div className="work-container">
            <div className="work-item">
              <img
                alt="braingineers"
                className="work-src work-centralapp"
                src={CentralApp}
              ></img>
              <div className="work-content">
                <h3 className="work-conent-title">
                  <a
                    href="https://centralapp.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    CentralApp
                  </a>
                </h3>
                <p className="work-conent-title work-content-p--first">
                  Nunjucks templating engine and React app.
                </p>
                <p className="work-content-p">Online presence solution</p>
                <p className="work-content-p">
                  implementation, design by{" "}
                  <a
                    href="https://www.antoinepirard.be/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Antoine Pirard
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="work-container">
            <div className="work-item">
              <img
                alt="braingineers"
                className="work-src work-counters"
                src={Counters}
              ></img>
              <div className="work-content">
                <h3 className="work-conent-title">
                  <a
                    href="https://ssbu-counters.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Counters
                  </a>
                </h3>
                <p className="work-conent-title work-content-p--first">
                  Moustache templating engine, node and mongo backend
                </p>
                <p className="work-content-p">
                  White labeled ad revenue content farm
                </p>
                <p className="work-content-p">
                  Architecture, implementation, design
                </p>
              </div>
            </div>
          </div>
          <div className="work-container">
            <div className="work-item">
              <img
                alt="braingineers"
                className="work-src work-nxchange"
                src={Nxchange}
              ></img>
              <div className="work-content">
                <h3 className="work-conent-title">
                  <a
                    href="https://nxchange.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Nxchange
                  </a>
                </h3>
                <p className="work-conent-title work-content-p--first">
                  Vue single page application
                </p>
                <p className="work-content-p">Digital stock market</p>
                <p className="work-content-p">
                  implementation, design by{" "}
                  <a
                    href="https://leifmichelsen.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Leif Michelsen
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="work-container">
            <div className="work-item">
              <img
                alt="braingineers"
                className="work-src work-burst"
                src={Burst}
              ></img>
              <div className="work-content">
                <h3 className="work-conent-title">
                  <span>Burst</span>
                </h3>
                <p className="work-conent-title work-content-p--first">
                  Nextjs sites, react app, gatsby marketing sites
                </p>
                <p className="work-content-p">
                  Esports infrastructure and content manager
                </p>
                <p className="work-content-p">
                  Architecture, implementation, design
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="footer">
        <div className="inner">
          <div className="connect-container connect-contact"></div>
          <div className="contact-container connect-contact">
            <div className="others">
              <a
                href="https://www.linkedin.com/in/nicholasbarth/"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>{" "}
              <a
                href="https://github.com/nick-barth"
                rel="noopener noreferrer"
                target="_blank"
              >
                Github
              </a>
              <span role="img" aria-label="crab man" className="bottom-crab">
                🦀
              </span>
            </div>
            <div className="email">barth.nicholas@gmail.com</div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    file(relativePath: { eq: "nick-barth.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allFile(filter: { extension: { eq: "pdf" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`
